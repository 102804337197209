<template>
  <div class="about">
    <HypersymbolicNavBar
      active-button="about"
    />
    <div style="height: 10vh"></div>
    <div>
      <SectionTitle
        color="#999999"
        dark
      >
        Legal
      </SectionTitle>
    </div>

    <v-container class="pt-10 d-flex justify-center">
      <v-col cols="11" xs="11" sm="10" md="10" lg="9" xl="9" class="grow">
        <h1>Terms and Conditions</h1>

        <p>
          This website (hypersymbolic.com) and all of the content and services provided therein are
          provided on an "as is" and "as available" basis. We (the owners of this website) expressly
          disclaims all warranties of any kind, whether express or implied, including, but not
          limited to, the implied warranties of merchantability, fitness for a particular purpose,
          title, non-infringement, and security and accuracy, as well as all warranties arising by
          usage of trade, course of dealing, or course of performance.
        </p>
        <p>
          We will not be responsible or liable for (a) any damages to or viruses that may
          infect your computer equipment or other property as the result of your access to this
          website or your downloading of any content from this website or (b) any injury, death,
          loss, claim, act of god, accident, delay, or any direct, special, exemplary, punitive,
          indirect, incidental or consequential damages of any kind (including without limitation
          lost profits or lost savings), whether based in contract, tort, strict liability or
          otherwise, that arise out of or is in any way connected with (i) any use of this Website
          or content, (ii) any failure or delay (including without limitation the use of or
          inability to use any component of this Website for reservations or ticketing), or (iii)
          the performance or non performance by us or any provider, even if we have been advised of
          the possibility of damages to such parties or any other party. You further understand and
          agree that we make no guarantees or warranties as to the accuracy or content of any of the
          content you choose to download and use from Our Website, or whether any content will be
          available or remain available in the future.
        </p>
        <p>
          This website contains links to other websites that we do not operate. We are not
          responsible for the contents on these other websites, nor do we endorse their contents.
        </p>

        <h1>Privacy Policy</h1>

        <p>
          Unless you use the <a href="/#contact">contact form</a>, we will not collect any
          personally identifying information about the visitors of this website.
        </p>
        <p>
          If you use the contact form, we will solely use the information provided to respond to
          your message.
        </p>
        <p>
          We do not serve advertisement or use cookies.
        </p>
        <h1>Copyright Notice</h1>
        <p>
          The "Hypersymbolic" name and the Hypersymbolic Logo were created in 2020 by Alexandre
          Gravier to represent the trade mark of Hypersymbolic.
        </p>
        <p>
          The Hypersymbolic logo and the logos of all Hypersymbolic products are protected by
          copyright held by Joanna Gravier-Rymaszewska and Alexandre Gravier (© 2020).
          The icons in the <a href="/#about">about</a> section of the website were created in 2020
          by Alexandre Gravier and are also protected by copyright. We do not authorize the copy and
          reuse of any of the material on this website, in part or in whole, unless explicitly
          stated otherwise.
        </p>
        <p>
          If you would like to use some material found on this website, you must get our explicit
          approval in written form. Please contact us using the <a href="/#contact">contact
          form</a>.
        </p>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import HypersymbolicNavBar from '@/components/HypersymbolicNavBar.vue';
import SectionTitle from '@/components/SectionTitle.vue';

export default {
  name: 'Legal',

  components: {
    HypersymbolicNavBar,
    SectionTitle,
  },
};
</script>

<style scoped>

</style>
